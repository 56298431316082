import Moment from 'moment';
import { LabelStringSet } from '../Label';

export const prettyDate = (date) => (Moment(date, 'x').fromNow());

export const getOthersLastSeenAt = (channel) => {
  if (!channel || !channel.getReadStatus || !channel.members || channel.members.length !== 2) {
    return '';
  }
  const lastSeenList = [...Object.values(channel.getReadStatus())];
  const lastSeenAt = lastSeenList.length > 0
    ? lastSeenList[0].last_seen_at
    : 0;
  if (lastSeenAt === 0) {
    return '';
  }
  return prettyDate(lastSeenAt);
};

export const getChannelTitle = (channel = {}, currentUserId) => {
  if (!channel || (!channel.name && !channel.members)) {
    return LabelStringSet.NO_TITLE;
  }
  if (channel.name && channel.name !== 'Group Channel') {
    return channel.name;
  }

  if (channel.members.length === 1) {
    return LabelStringSet.NO_MEMBERS;
  }

  return channel.members
    .filter(({ userId }) => userId !== currentUserId)
    .map(({ nickname }) => (nickname || LabelStringSet.NO_NAME))
    .join(', ');
};
