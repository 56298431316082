import { useEffect } from 'react';

import * as messageActions from '../dux/actionTypes';
import { uuidv4 } from '../../../utils/uuid';
import compareIds from '../../../utils/compareIds';

/**
 * Handles ChannelEvents and send values to dispatcher using messagesDispatcher
 * messagesDispatcher: Dispatcher
 * sdk: sdkInstance
 * logger: loggerInstance
 * channelUrl: string
 * sdkInit: bool
 */
function useHandleChannelEvents({ currentGroupChannel, sdkInit }, {
  messagesDispatcher,
  sdk,
  logger,
}) {
  const channelUrl = currentGroupChannel && currentGroupChannel.url;
  useEffect(() => {
    const messageReciverId = uuidv4();
    if (channelUrl && sdk && sdk.ChannelHandler) {
      const ChannelHandler = new sdk.ChannelHandler();
      logger.info('Channel | useHandleChannelEvents: Setup event handler', messageReciverId);
      ChannelHandler.onMessageReceived = (channel, message) => {
        if (compareIds(channel.url, currentGroupChannel.url)) {
          logger.info('Channel | useHandleChannelEvents: onMessageReceived', message);
          messagesDispatcher({
            type: messageActions.ON_MESSAGE_RECEIVED,
            payload: { channel, message },
          });
        }
      };

      ChannelHandler.onMessageUpdated = (_, message) => {
        logger.info('Channel | useHandleChannelEvents: onMessageUpdated', message);
        messagesDispatcher({
          type: messageActions.ON_MESSAGE_UPDATED,
          payload: message,
        });
      };

      ChannelHandler.onMessageDeleted = (_, messageId) => {
        logger.info('Channel | useHandleChannelEvents: onMessageDeleted', messageId);
        messagesDispatcher({
          type: messageActions.ON_MESSAGE_DELETED,
          payload: messageId,
        });
      };

      ChannelHandler.onReactionUpdated = (_, reactionEvent) => {
        logger.info('Channel | useHandleChannelEvents: onReactionUpdated', reactionEvent);
        messagesDispatcher({
          type: messageActions.ON_REACTION_UPDATED,
          payload: reactionEvent,
        });
      };

      ChannelHandler.onChannelChanged = (groupChannel) => {
        if (compareIds(groupChannel.url, currentGroupChannel.url)) {
          logger.info('Channel | useHandleChannelEvents: onChannelChanged', groupChannel);
          messagesDispatcher({
            type: messageActions.SET_CURRENT_CHANNEL,
            payload: groupChannel,
          });
        }
      };

      ChannelHandler.onChannelFrozen = (groupChannel) => {
        if (compareIds(groupChannel.url, currentGroupChannel.url)) {
          logger.info('Channel | useHandleChannelEvents: onChannelFrozen', groupChannel);
          messagesDispatcher({
            type: messageActions.SET_CURRENT_CHANNEL,
            payload: groupChannel,
          });
        }
      };

      ChannelHandler.onChannelUnfrozen = (groupChannel) => {
        if (compareIds(groupChannel.url, currentGroupChannel.url)) {
          logger.info('Channel | useHandleChannelEvents: onChannelUnFrozen', groupChannel);
          messagesDispatcher({
            type: messageActions.SET_CURRENT_CHANNEL,
            payload: groupChannel,
          });
        }
      };

      ChannelHandler.onUserMuted = (groupChannel) => {
        if (compareIds(groupChannel.url, currentGroupChannel.url)) {
          logger.info('Channel | useHandleChannelEvents: onUserMuted', groupChannel);
          messagesDispatcher({
            type: messageActions.SET_CURRENT_CHANNEL,
            payload: groupChannel,
          });
        }
      };

      ChannelHandler.onUserUnmuted = (groupChannel) => {
        if (compareIds(groupChannel.url, currentGroupChannel.url)) {
          logger.info('Channel | useHandleChannelEvents: onUserUnmuted', groupChannel);
          messagesDispatcher({
            type: messageActions.SET_CURRENT_CHANNEL,
            payload: groupChannel,
          });
        }
      };

      ChannelHandler.onUserBanned = (groupChannel) => {
        if (compareIds(groupChannel.url, currentGroupChannel.url)) {
          logger.info('Channel | useHandleChannelEvents: onUserBanned', groupChannel);
          messagesDispatcher({
            type: messageActions.SET_CURRENT_CHANNEL,
            payload: groupChannel,
          });
        }
      };

      ChannelHandler.onOperatorUpdated = (groupChannel) => {
        if (compareIds(groupChannel.url, currentGroupChannel.url)) {
          logger.info('Channel | useHandleChannelEvents: onOperatorUpdated', groupChannel);
          messagesDispatcher({
            type: messageActions.SET_CURRENT_CHANNEL,
            payload: groupChannel,
          });
        }
      };

      // Add this channel event handler to the SendBird object.
      sdk.addChannelHandler(messageReciverId, ChannelHandler);
    }
    return () => {
      if (sdk && sdk.removeChannelHandler) {
        logger.info('Channel | useHandleChannelEvents: Removing message reciver handler', messageReciverId);
        sdk.removeChannelHandler(messageReciverId);
      }
    };
  }, [channelUrl, sdkInit]);
}

export default useHandleChannelEvents;
