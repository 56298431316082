import React, { useMemo } from 'react';

import Label, { LabelTypography, LabelColors, LabelStringSet } from '../Label';
import LinkLabel from '../LinkLabel';
import uuidv4 from '../../utils/uuid';
import { createUrlTester } from './utils';
import { URL_REG } from './const';

const WORD_TYPOGRAPHY = LabelTypography.BODY_1;
const WORD_COLOR = LabelColors.ONBACKGROUND_1;
const EDITED_COLOR = LabelColors.ONBACKGROUND_2;
const isUrl = createUrlTester(URL_REG);

export default function useMemoizedMessageText({
  message,
  updatedAt,
  className,
}) {
  return useMemo(() => () => {
    const splitMessage = message.split(' ');
    const matchedMessage = splitMessage
      .map((word) => (
        isUrl(word)
          ? (
            <LinkLabel
              key={uuidv4()}
              className={className}
              src={word}
              type={WORD_TYPOGRAPHY}
              color={WORD_COLOR}
            >
              {word}
            </LinkLabel>
          )
          : (
            <Label
              key={uuidv4()}
              className={className}
              type={WORD_TYPOGRAPHY}
              color={WORD_COLOR}
            >
              {word}
            </Label>
          )
      ));
    if (updatedAt > 0) {
      matchedMessage.push(
        <Label
          key={uuidv4()}
          className={className}
          type={WORD_TYPOGRAPHY}
          color={EDITED_COLOR}
        >
          {LabelStringSet.MESSAGE_EDITED}
        </Label>,
      );
    }
    // return (<>{matchedMessage}</>);
    return matchedMessage;
  }, [message, updatedAt, className]);
}
