import React from 'react';

import './notification.scss';

import Label, {
  LabelStringSet,
  LabelTypography,
} from '../../../ui/Label';

const FrozenNotification = (): JSX.Element => {
  return (
    <div className="sendbird-notification sendbird-notification--frozen">
      <Label
        className="sendbird-notification__text"
        type={LabelTypography.CAPTION_2}
      >
        {LabelStringSet.CHANNEL_FROZEN}
      </Label>
    </div>
  );
}

export default FrozenNotification;
