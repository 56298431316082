import React from 'react';

import Label, {
  LabelStringSet,
  LabelTypography,
  LabelColors,
} from '../Label';
import Icon, { IconTypes, IconColors } from '../Icon';

import './index.scss';

function ConnectionStatus() {
  return (
    <div
      className="sendbird-conversation__connection-status"
    >
      <Label type={LabelTypography.BODY_2} color={LabelColors.ONBACKGROUND_2}>
        { LabelStringSet.TRYING_TO_CONNECT }
      </Label>
      <Icon
        type={IconTypes.DISCONNECTED}
        fillColor={IconColors.SENT}
        height="14px"
        width="14px"
      />
    </div>
  );
}

export default ConnectionStatus;
