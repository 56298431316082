import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './channel-profile.scss';

import Label, { LabelColors, LabelTypography, LabelStringSet } from '../../../ui/Label';
import EditDetailsModal from './EditDetails';
import TextButton from '../../../ui/TextButton';
import ChannelAvatar from '../../../ui/ChannelAvatar/index';

const ChannelProfile = (props) => {
  const {
    disabled,
    channel,
    userId,
    theme,
    onChannelInfoChange,
  } = props;
  const title = channel.name;
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="sendbird-channel-profile">
      <div className="sendbird-channel-profile--inner">
        <div className="sendbird-channel-profile__avatar">
          <ChannelAvatar
            channel={channel}
            userId={userId}
            theme={theme}
          />
        </div>
        <Label
          type={LabelTypography.SUBTITLE_2}
          color={LabelColors.ONBACKGROUND_1}
          className="sendbird-channel-profile__title"
        >
          {title}
        </Label>
        <TextButton
          disabled={disabled}
          className="sendbird-channel-profile__edit"
          onClick={() => {
            if (disabled) { return; }
            setShowModal(true);
          }}
          notUnderline
        >
          <Label
            type={LabelTypography.BUTTON_1}
            color={disabled ? LabelColors.ONBACKGROUND_2 : LabelColors.PRIMARY}
          >
            {LabelStringSet.CHANNEL_SETTING__PROFILE__EDIT}
          </Label>
        </TextButton>
        {
          showModal && (
            <EditDetailsModal
              onCancel={() => setShowModal(false)}
              onSubmit={onChannelInfoChange}
              channel={channel}
              userId={userId}
              theme={theme}
            />
          )
        }
      </div>
    </div>
  );
};

ChannelProfile.propTypes = {
  channel: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  userId: PropTypes.string.isRequired,
  theme: PropTypes.string,
  disabled: PropTypes.bool,
  onChannelInfoChange: PropTypes.func,
};

ChannelProfile.defaultProps = {
  theme: 'light',
  disabled: false,
  onChannelInfoChange: () => { },
};

export default ChannelProfile;
