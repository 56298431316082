import Moment from 'moment';
import MessageStatusType from '../MessageStatus/type';

export const getMessageCreatedAt = (message) => Moment(message.createdAt).format('LT');

export const getIsSentFromStatus = (status) => (
  status === MessageStatusType.SENT
  || status === MessageStatusType.DELIVERED
  || status === MessageStatusType.READ
);

export default {
  getMessageCreatedAt,
  getIsSentFromStatus,
};
