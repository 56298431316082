import Moment from 'moment';
import { truncate } from '../FileMessage/utils';
import { LabelStringSet } from '../Label';

export const getChannelTitle = (channel = {}, currentUserId) => {
  if (!channel || (!channel.name && !channel.members)) {
    return LabelStringSet.NO_TITLE;
  }
  if (channel.name && channel.name !== 'Group Channel') {
    return channel.name;
  }
  if (channel.members.length === 1) {
    return LabelStringSet.NO_MEMBERS;
  }
  return channel.members
    .filter(({ userId }) => userId !== currentUserId)
    .map(({ nickname }) => (nickname || LabelStringSet.NO_NAME))
    .join(', ');
};

export const prettyDate = (date) => (Moment(date, 'x').fromNow());

export const getLastMessageCreatedAt = (channel) => {
  if (!channel || !channel.lastMessage) {
    return '';
  }
  const moment = Moment(channel.lastMessage.createdAt);
  switch (moment.calendar().split(' ')[0]) {
    case 'Today': {
      return moment.format('LT');
    }
    case 'Yesterday': {
      return 'Yesterday';
    }
    default: return moment.format('ll').split(',')[0];
  }
};

export const getTotalMembers = (channel) => (
  channel && channel.memberCount
    ? channel.memberCount
    : 0
);

const getPrettyLastMessage = (message = {}) => {
  const MAXLEN = 30;
  const { messageType, name } = message;
  if (messageType === 'file') {
    return truncate(name, MAXLEN);
  }
  return message.message;
};

export const getLastMessage = (channel) => (
  channel && channel.lastMessage
    ? getPrettyLastMessage(channel.lastMessage)
    : ''
);

export const getChannelUnreadMessageCount = (channel) => (
  (channel && channel.unreadMessageCount)
    ? channel.unreadMessageCount
    : 0
);
