import React, { useMemo } from 'react';

import Label, { LabelTypography, LabelColors, LabelStringSet } from '../Label';
import uuidv4 from '../../utils/uuid';

const WORD_TYPOGRAPHY = LabelTypography.BODY_1;
const EDITED_COLOR = LabelColors.ONBACKGROUND_2;

export default function useMemoizedMessageText({
  message,
  updatedAt,
  className,
}) {
  return useMemo(() => () => {
    const splitMessage = message.split(/\r/);
    const matchedMessage = splitMessage
      .map((word) => (word !== '' ? word : <br />));
    if (updatedAt > 0) {
      matchedMessage.push(
        <Label
          key={uuidv4()}
          className={className}
          type={WORD_TYPOGRAPHY}
          color={EDITED_COLOR}
        >
          {` ${LabelStringSet.MESSAGE_EDITED} `}
        </Label>,
      );
    }
    return matchedMessage;
  }, [message, updatedAt, className]);
}
